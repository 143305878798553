

    .temp {
        min-height: 40px;
    }

    .skeleton-shape {
        display: flex;
        background: var(--global-muted-background);
        background-image: linear-gradient(to right, var(--global-muted-background) 0%, var(--global-inverse-color) 20%, var(--global-muted-background) 40%, var(--global-muted-background) 100%);
        background-repeat: no-repeat;
        background-size: 200% 100%;
        position: relative;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeholderShimmer;
        -webkit-animation-timing-function: linear;

    }

    .skeleton-wrapper--inverse {
        .skeleton-shape {
            background: var(--global-inverse-color);
            background-image: linear-gradient(to right, var(--global-inverse-color) 0%, var(--global-color) 20%, var(--global-inverse-color) 40%, var(--global-inverse-color) 100%);
            background-repeat: no-repeat;
            background-size: 200% 100%;
            position: relative;
            -webkit-animation-duration: 1s;
            -webkit-animation-fill-mode: forwards;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-name: placeholderShimmer;
            -webkit-animation-timing-function: linear;
        }

    }

    .line {
        flex: 100%;
        height: calc(var(--global-margin) * 1.5);
        margin-bottom: calc(var(--global-margin));
    }

    .line.line--thin {
        height: calc(var(--global-margin) * 1 / 2);
        margin-bottom: calc(var(--global-margin) * 0.7 / 2);
    }


    .circle {
        display: flex;
        border-radius: 50%;
    }

    .skeleton-inner.skeleton-inner--story {
        padding: 20px;
        display: flex;

        .skeleton-story-block {
            flex: 70px;
            margin-inline-end: var(--global-margin);

        }

        .skeleton-story-lines {
            display: block;
            flex: 100%;
            width: 100%;
            flex-wrap: wrap;

            .line {
                flex: 100%;
                width: 100%;
                height: calc(var(--global-margin) * 1);
                margin-bottom: calc(var(--global-margin) * 0.7);
            }

            .story-line-1 {
                width: 70%;
            }

            .story-line-2 {
                width: 80%;
            }

            .story-line-3 {
                width: 50%;
            }
        }
    }

    .skeleton-inner--form  {
        display: block;

        .skeleton-form-block  {
            display: block;

            .line {
                display: block;
            }

            .skeleton-form-title  {
                height: 14px;
                margin-bottom: 10px;
                width: 30%;

            }
            .skeleton-form-field {
                height: 40px;
                margin-bottom: 20px;
                width: 100%;
            }
        }
    }




    @-webkit-keyframes placeholderShimmer {
        0% {
            background-position: 150% 0;
        }

        100% {
            background-position: -150% 0;
        }
    }


